<template>
  <div class="min-h-screen flex flex-col">
    <header>
      <div class="bg-blue-primary border-b-2 border-red-500">
        <div class="mx-auto px-2 sm:px-4 lg:px-8">
          <div class="flex h-16 justify-between">
            <LayoutHeaderLogo />
          </div>
        </div>
      </div>
    </header>
    <div class="flex-1">
      <slot />
    </div>
    <LayoutFooter />
  </div>
</template>
